import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Page from '../components/page';
import ProduceCard from '../components/produceCard';
import pageData from '../content/pages/produce.yml';

function useProduceData() {
  const { produce, images } = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativePath: { glob: "produce/*" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              id
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      produce: allMarkdownRemark {
        group(field: frontmatter___category) {
          fieldValue
          edges {
            node {
              frontmatter {
                title
                peak_season
                origin
                pref_temperature
                produce_image
                rel_humidity
              }
            }
          }
        }
      }
    }
  `);

  let defaultImage = images.edges.find(({ node }) =>
    node.relativePath.includes('none.png')
  );

  let response = {};
  for (let category of produce.group) {
    response[category.fieldValue] = category.edges.map(({ node }) => ({
      ...node.frontmatter,
      category: category.fieldValue,
      image:
        images.edges.find(({ node: imgNode }) =>
          node.frontmatter.produce_image.includes(imgNode.relativePath)
        ) || defaultImage,
    }));
  }

  return response;
}

const Produce = () => {
  const produce = useProduceData();

  return (
    <Layout>
      <SEO title="Produce"></SEO>
      <Page>
        <Page.Header title={pageData.title} subtitle={pageData.subtitle} />
        <section className="section">
          {Object.entries(produce).map(([category, items]) => (
            <React.Fragment key={category}>
              <div className="peak-produce-heading">
                <div className="container">
                  <h2
                    id={category.replace(/[^A-Za-z]*/g, '').toLowerCase()}
                    className="title is-4"
                    key={category}
                  >
                    {category}
                  </h2>
                  <hr className="divider" />
                </div>
              </div>
              <div className="container">
                <div className="columns is-multiline">
                  {items ? (
                    items.map(item => (
                      <div
                        key={item.title}
                        className="column is-one-third is-mobile-12"
                      >
                        <ProduceCard produce={item} />
                      </div>
                    ))
                  ) : (
                    <p>No items in this category</p>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </section>
      </Page>
    </Layout>
  );
};

export default Produce;
