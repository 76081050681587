import React from 'react';
import Img from 'gatsby-image';

const propertiesMap = new Map([
  ['peak_season', 'Peak season'],
  ['origin', 'Country of origin'],
  ['pref_temperature', 'Preferred temperature'],
  ['rel_humidity', 'Relative humidity'],
]);

const ProduceCard = ({ produce }) => {
  const properties = Array.from(propertiesMap.entries()).map(
    ([key, label], i) => {
      if (!produce[key]) return '';
      return (
        <p key={key} className="peak-produce-attribute">
          <strong>{`${label}: `}</strong>
          {produce[key]}
        </p>
      );
    }
  );

  return (
    <div className="card">
      <div className="card-image">
        {produce.image && (
          <Img
            fluid={produce.image.node.childImageSharp.fluid}
            alt={produce.title}
          />
        )}
      </div>
      <div className="card-content">
        <div className="content">
          <p className="title is-5">{produce.title}</p>
          <p className="subtitle is-6">{produce.category}</p>
          {properties}
        </div>
      </div>
    </div>
  );
};

export default ProduceCard;
